import { api } from "@/apps/common/api-client";
import { useRequest } from "ahooks";
import Markdown from "markdown-to-jsx";
import { Accordion, Card, Col } from "react-bootstrap";

export default function AppointmentDiagnosisAid({
  appointmentId
}: {
  appointmentId: number;
}) {
  const { data: aids = [] } = useRequest(() =>
    api.diagnosisAid.getByAppointment(Number(appointmentId))
  );

  const latestAid = aids[0];

  if (!latestAid?.possibleDiagnoses) {
    return null;
  }

  return (
    <Col>
      <Card>
        <Card.Body>
          <Card.Title as={"h4"}>Diagnosis Aid</Card.Title>
          <Accordion>
            {latestAid.possibleDiagnoses ? (
              <Accordion.Item eventKey="possibleDiagnoses">
                <Accordion.Header>Possible Diagnoses</Accordion.Header>
                <Accordion.Body>
                  <Markdown>{latestAid.possibleDiagnoses}</Markdown>
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
            {latestAid.diagnosisCodeSuggestions ? (
              <Accordion.Item eventKey="diagnosisCodeSuggestions">
                <Accordion.Header>Suggested Diagnosis Code</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <Markdown>{latestAid.diagnosisCodeSuggestions!}</Markdown>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ) : null}
          </Accordion>
        </Card.Body>
      </Card>
    </Col>
  );
}
